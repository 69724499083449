import React from 'react'
import Header from '../components/common/Header'
import AppCTA from '../components/cta/AppCTA'
import ContactCTA from '../components/cta/ContactCTA'
import Layout from '../components/layout'
import Form from '../components/request/Form'
import Seo from '../components/seo'

class RootIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo
          title="Request a public EV chargepoint near your home | Urban Fox"
          description="Tired of looking for an “EV charge point near me”?  Request a public EV charge point near your home using the form below and we’ll pass it onto the relevant local authority."
        />
        <Header filter="Request" />
        <Form />
        <ContactCTA />
        <AppCTA topPadded />
      </Layout>
    )
  }
}

export default RootIndex
