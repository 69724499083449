import { Switch } from '@headlessui/react'
import React, { useState } from 'react'
import Button from '../common/Button'

const Form = () => {
  const [agreed, setAgreed] = useState(false)
  const [marketingOptIn, setMarketingOptIn] = useState(false)

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  return (
    <div className="mt-2 px-4 max-w-4xl mx-auto py-24">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Fill in the form to request an EV chargepoint
      </h2>
      <form
        name="request-chargepoint"
        method="POST"
        data-netlify="true"
        className="flex flex-col gap-4 mt-4"
      >
        <input type="hidden" name="form-name" value="request-chargepoint" />
        <div>
          <label
            htmlFor="first-name"
            className="block text-sm font-medium text-gray-700"
          >
            First name
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="first-name"
              id="first-name"
              autoComplete="given-name"
              className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-uforange-600 focus:ring-uforange-600"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="last-name"
            className="block text-sm font-medium text-gray-700"
          >
            Last name
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="last-name"
              id="last-name"
              autoComplete="family-name"
              className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-uforange-600 focus:ring-uforange-600"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email
          </label>
          <div className="mt-1">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-uforange-600 focus:ring-uforange-600"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="company"
            className="block text-sm font-medium text-gray-700"
          >
            Postcode
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="company"
              id="company"
              autoComplete="organization"
              className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-uforange-600 focus:ring-uforange-600"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="why"
            className="block text-sm font-medium text-gray-700"
          >
            Why is this chargepoint needed?
          </label>
          <div className="mt-1">
            <textarea
              id="why"
              name="why"
              rows={4}
              className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-uforange-600 focus:ring-uforange-600"
              defaultValue={''}
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="message"
            className="block text-sm font-medium text-gray-700"
          >
            Any other information
          </label>
          <div className="mt-1">
            <textarea
              id="message"
              name="message"
              rows={4}
              className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-uforange-600 focus:ring-uforange-600"
              defaultValue={''}
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <div className="flex items-center mt-4">
            <Switch
              checked={marketingOptIn}
              onChange={setMarketingOptIn}
              className={classNames(
                marketingOptIn ? 'bg-uforange-600' : 'bg-gray-200',
                'relative inline-flex items-center h-6 rounded-full w-11'
              )}
            >
              <span
                className={classNames(
                  marketingOptIn ? 'translate-x-6' : 'translate-x-1',
                  'inline-block w-4 h-4 transform bg-white rounded-full'
                )}
              />
            </Switch>
            <label
              htmlFor="marketing-opt-in"
              className="ml-3 block text-base text-gray-500"
            >
              By selecting this you agree to receive marketing communications
              from Urban Fox.
            </label>
            <input
              type="hidden"
              name="marketing-opt-in"
              value={marketingOptIn ? 'yes' : 'no'}
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <Switch
                checked={agreed}
                onChange={setAgreed}
                className={classNames(
                  agreed ? 'bg-uforange-600' : 'bg-gray-200',
                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-uforange-600 focus:ring-offset-2'
                )}
              >
                <span className="sr-only">Agree to policies</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    agreed ? 'translate-x-5' : 'translate-x-0',
                    'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                  )}
                />
              </Switch>
            </div>
            <div className="ml-3">
              <p className="text-base text-gray-500">
                By selecting this, you agree to the{' '}
                <a
                  href="/privacy"
                  className="font-medium text-gray-700 underline"
                >
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a
                  href="/cookies"
                  className="font-medium text-gray-700 underline"
                >
                  Cookie Policy
                </a>
                .
              </p>
            </div>
          </div>
        </div>
        <Button
          type="submit"
          label="Request a chargepoint"
          disabled={!agreed}
        />
      </form>
    </div>
  )
}

export default Form
